import React, { useState } from 'react';
import { injectIntl } from 'gatsby-plugin-intl';


const privacyNotice = () => {
    const [texts, setTexts] = useState([]);

    setTexts(
        [
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae ipsum, nemo dolores doloremque veniam ut officia tempora dolore id vitae unde vel architecto atque! Quo enim reprehenderit ipsa iste nemo necessitatibus omnis sequi, asperiores nesciunt eum, maiores, inventore culpa. Et, hic quam quasi ratione perferendis nesciunt nam laudantium soluta veritatis.",
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae ipsum, nemo dolores doloremque veniam ut officia tempora dolore id vitae unde vel architecto atque! Quo enim reprehenderit ipsa iste nemo necessitatibus omnis sequi, asperiores nesciunt eum, maiores, inventore culpa. Et, hic quam quasi ratione perferendis nesciunt nam laudantium soluta veritatis.",
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae ipsum, nemo dolores doloremque veniam ut officia tempora dolore id vitae unde vel architecto atque! Quo enim reprehenderit ipsa iste nemo necessitatibus omnis sequi, asperiores nesciunt eum, maiores, inventore culpa. Et, hic quam quasi ratione perferendis nesciunt nam laudantium soluta veritatis.",
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae ipsum, nemo dolores doloremque veniam ut officia tempora dolore id vitae unde vel architecto atque! Quo enim reprehenderit ipsa iste nemo necessitatibus omnis sequi, asperiores nesciunt eum, maiores, inventore culpa. Et, hic quam quasi ratione perferendis nesciunt nam laudantium soluta veritatis.",
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae ipsum, nemo dolores doloremque veniam ut officia tempora dolore id vitae unde vel architecto atque! Quo enim reprehenderit ipsa iste nemo necessitatibus omnis sequi, asperiores nesciunt eum, maiores, inventore culpa. Et, hic quam quasi ratione perferendis nesciunt nam laudantium soluta veritatis."
        ]
    );

    const renderText = (value, id) => {
        return <p key={id}>{value}</p>;
    };

    return (
        <div className="privacyNotice">
            <div className="privacyNoticeTitle">
                <h1 className="title">
                    Privacy Notice
                </h1>
            </div>
            <div className="privacyNoticeContent">
                texts.map(renderText)
            </div>
        </div>
    );
};

export default injectIntl(privacyNotice);
