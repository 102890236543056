import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import PrivacyNoticeContent from '../components/privacyNotice';
import Blog from '../components/blog';

import ThemeContext from '../context/theme-context';
import Contact from './contact';

class PrivacyNotice extends React.Component {
    render() {
        return (
            <Layout>
                <SEO title="Privacy Notice"/>
                <PrivacyNoticeContent />
                <Blog />
            </Layout>
        );
    }
}

Contact.contextType = ThemeContext;

export default Contact;
